
.download_icon, .edit_icon, .status {
    /*width: 20px;*/
    font-size: 20px;
    /*height: 20px;*/
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.download_icon {

    color: #000000;
}


.download_icon:hover {
    color: #27de49;
}

.edit_icon {
    color: #2c31de;
}

.edit_icon:hover {
    color: #cc1be1;
}

.row_item > div {
    margin-bottom: 15px
}


.file {
    border: 1px solid #e0e0e0;
    padding: 10px;
    min-height: 70px;
}

.file:hover {
    background-color: #f0f0f0;
}