.flex {
    display:  grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;

}

.no-margin {
    margin: 0;
    padding: 0;

}
